<template>
  <a
    class="card"
    :href="`/course/center?id=${dataSource.courseId}${
      newWindow == 1 ? '&ddtab=true' : ''
    }`"
    :target="newWindow == 1 ? '_blank' : '_self'"
  >
    <div class="tag" v-if="sourceType == 'mine' && dataSource.progress == 100">
      {{ $t("CM_Completed") }}
      <!-- 已完成 -->
    </div>
    <div class="cover">
      <img :src="dataSource.coverImg" alt="cover" />
    </div>
    <div class="content" :class="{ gray: sourceType == 'mine' }">
      <h5>{{ dataSource.courseName }}</h5>
      <div class="info" v-if="sourceType == 'list'">
        <div class="item" v-if="dataSource.lecturers">
          <template
            v-for="(teacher, index) in dataSource.lecturers"
            :key="teacher.id"
          >
            <div class="teacher" v-if="index < 1">
              <img v-if="teacher.portrait" :src="teacher.portrait" />
              <img v-else src="../../assets/image/avatar.png" />
              <span>
                <OpenData type="userName" :openid="teacher.name" />
                <template v-if="index == 0 && dataSource.lecturers.length > 1">
                  ...
                </template>
              </span>
            </div>
          </template>
        </div>
        <div class="item" v-else>
          <div class="teacher">
            <img src="../../assets/image/avatar.png" />
            <span>{{ $t("no_lecturer") }}</span>
            <!-- 暂无讲师 -->
          </div>
        </div>
        <div class="item">
          <LikeOutlined
            style="color: #999; font-size: 15px; margin-bottom: 4px"
          />
          <span style="margin: 0 6px 0 4px">{{ dataSource.likes }}</span>
          <EyeOutlined style="color: #999; font-size: 15px; margin-top: -1px" />
          <span>{{ dataSource.learnCount }}</span>
        </div>
      </div>
      <div class="info" v-else-if="sourceType == 'mine'">
        <div class="item">
          {{ $t("Pub_Tab_LastStudy") }}：{{ dateFormat(dataSource.lastTime) }}
        </div>
        <!-- 上次学习： -->
        <div class="item">{{ dataSource.progress }}%</div>
      </div>
      <div class="info" v-else-if="sourceType == 'teacher'">
        <div class="item">
          <a-rate
            style="font-size: 14px"
            :value="dataSource.starScore"
            disabled
          />
          <span class="star"
            >{{ dataSource.starScore }} {{ $t("CM_Points") }}</span
          >
          <!-- 分 -->
        </div>
        <div class="item">
          {{ $t("cm_views") }}：{{ dataSource.learnCount }}
        </div>
        <!-- 浏览量 -->
      </div>
    </div>
  </a>
</template>

<script>
import { useStore } from "vuex";
import { dateFormat } from "@/utils/tools";
import OpenData from "@/components/OpenData.vue";
export default {
  name: "CourseCard",
  components: {
    OpenData,
  },
  props: {
    sourceType: {
      type: String,
      default: "list",
    },
    dataSource: {
      type: Object,
      default: {},
    },
  },
  setup() {
    const store = useStore();
    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      dateFormat,
    };
  },
};
</script>

<style lang="less" scoped>
.card {
  display: block;
  width: 282px;
  height: 279px;
  border-radius: 8px;
  background-color: #fff;
  transition: all 0.3s ease;
  position: relative;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  .tag {
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: red;
    color: #fff;
    font-size: 12px;
    line-height: 24px;
    padding: 0 5px;
    z-index: 2;
    border-radius: 4px;
  }
  .cover {
    .mixinImgWrap(282px; 159px);
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    position: relative;
    &::after {
      content: "";
      background-color: rgba(255, 255, 255, 0);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: all 0.3s ease;
    }
  }
  .content {
    padding: 20px;
    border-radius: 0 0 8px 8px;
    &.gray {
      background-color: #f9f9f9;
    }
    h5 {
      font-size: 16px;
      .mixinEllipsis(46px, 2);
      margin: 0;
    }
    .info {
      color: #999;
      font-size: 12px;
      .mixinFlex(space-between; center);
      padding-top: 10px;
      .item {
        .mixinFlex(flex-start; center);
        .teacher {
          .mixinFlex(center; center);
          margin-right: 6px;
          img {
            width: 22px;
            height: 22px;
            border-radius: 50%;
          }
        }
        .star {
          color: #fadc4b;
          font-size: 14px;
          margin-left: 8px;
        }
        ::v-deep(.ant-rate-star:not(:last-child)) {
          margin-right: 4px;
        }
      }
      span:last-child {
        margin-left: 4px;
      }
    }
  }
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.25);
    .cover {
      &::after {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
</style>
